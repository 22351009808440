enum AutoInstantActivationRoutes {
    AuthorizationForm = "/",
    AppPromotion = "/promotion",
    AdditionalInfoNeeded = "/error",
    ContactCustomerService = "/contact_support_error",
}

enum ChangePasswordRoutes {
    Start = "/",
    ContactCustomerService = "/error",
}

enum LinkWithDemographicsRoutes {
    Form = "/",
    ContactCustomerService = "/contact_support_error",
}

enum MfaRoutes {
    RegisterEmail = "/",
    VerifyEmail = "/mfa-verification-code",
    ContactCustomerService = "/error",
    Success = "/success",
}

enum RootAuthenticatedRoutes {
    DebugMenu = "/",
    AutoInstantActivation = "/autoinstantactivation",
    Registration = "/register",
    LinkWithDemographics = "/demographics",
    ChangePassword = "/change-password",
    MfaRegistration = "/mfa-setup",
}

enum RootUnauthenticatedRoutes {
    UsernameRecovery = "/username-recovery",
    AutoInstantActivationLandingPage = "/instantactivation",
    AutoInstantActivationInvalidLink = "/error",
}

enum UsernameRecoveryRoutes {
    Start = "/",
    HandOff = "/hand-off",
    ContactCustomerService = "/error",
}

enum VerificationRoutes {
    VerificationMenu = "/",
    ActivationCodeFlow = "/activation",
    StartDemographicsFlow = "/phone",
    ActivationCodeExpired = "/phone-expired",
    SmsVerification = "/verification-code",
    DemographicsIntro = "/demographics-intro",
    DemographicsForm = "/demographics",
    IdentityCreation = "/creating",
    ContactCustomerService = "/error",
}

export const AuthenticatedRoutes = {
    RootRoutes: RootAuthenticatedRoutes,
    VerificationRoutes,
    AutoInstantActivationRoutes,
    LinkWithDemographicsRoutes,
    ChangePasswordRoutes,
    MfaRoutes,
};

export const UnauthenticatedRoutes = {
    RootRoutes: RootUnauthenticatedRoutes,
    UsernameRecoveryRoutes,
};
