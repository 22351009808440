import { Request } from 'express';

export enum AuthorizationStatusType {
    Authorized = 'Authorized',
    Unauthorized = 'Unauthorized',
    EpiMismatchError = 'EPI-Mismatch-Error',
    MyChartMismatchError = 'MyChart-Mismatch-Error',
}

enum GetAuthorizationResponseType {
    Success,
    CannotGetJWT,
    InvalidToken,
    UnknownError,
}

// TODO: Use PIMS cache instead of passing this stuff around
type GetAuthorizationRequestBase = {
    details: {
        instantActivationCode: string;
        isNonPatientProxy: boolean;
    };
};

type GetAuthorizationSuccess = GetAuthorizationRequestBase & {
    status: GetAuthorizationResponseType.Success;
    authorizationStatus: AuthorizationStatusType;
};

type GetAuthorizationFailure = GetAuthorizationRequestBase & {
    status:
        | GetAuthorizationResponseType.InvalidToken
        | GetAuthorizationResponseType.UnknownError
        | GetAuthorizationResponseType.CannotGetJWT;
};

export type GetAuthorizationResponse = GetAuthorizationSuccess | GetAuthorizationFailure;

export type PimsGetAuthorizationResponse = {
    aiaCodeAuthorizationStatus: AuthorizationStatusType;
};

export type PimsPostVerificationSessionResponse = {
    verificationSessionId: string;
    sessionTokenId: string;
};

export type PimsPostVerificationSessionRequest = {
    jwt: string;
    pimsApiUrl: string;
    brand: string;
    ehrSystem: string;
    deviceType: string;
    vendor: string;
};

export enum SessionStatus {
    NONE = 'No Session Found',
    IN_PROGRESS = 'Existing Session In Progress',
    COMPLETED = 'Session Completed',
}

export type PimsGetVerificationSessionResponse = {
    status: SessionStatus;
    token?: string;
};

export type PimsAuthorizeByAutoInstantActivationResponse = {
    consumerId: string;
    authorizedSystem: string;
};

export type PimsActivateMyChartResponse = {
    wasSuccessful: boolean;
    error?: string;
};

/*
 * AIA /getEmail response
 */

type GetEmailResponseFailure = {
    error: string;
};

type GetEmailResponseSuccess = {
    email: string;
    isNonPatientProxy: boolean;
    isTokenValid: boolean;
    analyticsProperties: {
        activation_method: string;
        activation_source: string;
        link_expired: string;
        epi_authorized: string;
    };
};

export type GetEmailResponse = GetEmailResponseSuccess | GetEmailResponseFailure;

/**
 * AIA /selectEmail request
 */

export type SelectEmailRequest = Request<
    any,
    any,
    {
        brand: string;
        ehrSystem: string;
        email: string;
        isExpired: boolean;
        isNonPatientProxy: boolean;
        token: string;
        deviceId?: string;
        sessionId?: number;
    }
>;
